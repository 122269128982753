/* https://create-react-app.dev/docs/adding-css-reset */
@import-normalize;

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
} */

body {
  margin: 0;
}

main {
  overflow: hidden;
  min-height: calc(100vh - 111px);
  /* min-height: calc(100vh - 141px); */
}

section {
  margin: 30px 0;
  /* margin: 60px 0; */
}

/* img,
iframe {
  vertical-align: middle;
} */

/* Sometimes you really don't want to scroll */
.no-scroll {
  overflow: hidden !important;
}

/* Hide stuff sometimes */
.hidden {
  display: none !important;
}

@import '/src/styles/mixins';
@import '/src/styles/variables';

$iconHeight: 48px;
$iconWidth: 56px;

.wrap {
  position: relative;
  margin-bottom: 24px;
  background: white;

  header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $iconHeight * 2;
    line-height: 1;
    @include medium() {
      height: $iconHeight;
    }
  }

  blockquote {
    margin: 20px;
  }

  a {
    color: $grey;
  }
}

.content {
  padding: 0 10px 5px;
  color: $dark;

  @include medium() {
    padding-left: $iconWidth + 10px;
  }
  @include large() {
    padding: 5px 15px 10px $iconWidth + 15px;
  }
  @include giant() {
    padding: 10px $iconWidth 15px $iconWidth + 20px;
  }
}

.name {
  position: absolute;
  top: 24px;
  padding: 0 10px;
  width: 100%;
  transform: translateY(-50%);
  @include CircularBlack();
  @include fluidType(18px, 24px);
  text-transform: uppercase;
  text-align: center;
  color: white;

  @include medium() {
    padding: 0 ($iconWidth + 10px);
  }
}

.retweets {
  position: absolute;
  top: $iconHeight;
  right: $iconWidth;
  left: $iconWidth;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $iconHeight;
  background: black;
  color: white;
  @include CircularBlack();
  @include fluidType(18px, 24px);
  span {
    position: relative;
    top: -2px;
    margin-left: 5px;
  }

  @include medium() {
    right: inherit;
    top: inherit;
    width: $iconWidth * 2;
    transform: translate(-100%, 100%);
  }
}

.position {
  position: absolute;
  top: $iconHeight;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $iconHeight;
  width: $iconWidth;
  background: white;
  font-size: 16px;
  span {
    @include CircularBlack();
    font-size: 30px;
  }

  @include medium() {
    top: 0;
  }
}

.icon {
  position: absolute;
  top: $iconHeight;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $iconHeight;
  width: $iconWidth;
  background: white;

  @include medium() {
    top: 0;
  }
}

.megaNumber {
  @include CircularBlack();
  font-size: 420px;
  font-weight: bold;
  position: absolute;
  top: 0;
  line-height: 1;
  color: $grey;
  z-index: -1;
  left: 0;
  transform: translate(-75%, -10%);
  letter-spacing: -30px;
}

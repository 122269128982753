// mixins
@import './variables.scss';

// All the responsives
@mixin tiny {
  @media screen and (min-width: #{$tiny}) {
    @content;
  }
}
@mixin small {
  @media screen and (min-width: #{$small}) {
    @content;
  }
}
@mixin medium {
  @media screen and (min-width: #{$medium}) {
    @content;
  }
}
@mixin large {
  @media screen and (min-width: #{$large}) {
    @content;
  }
}
@mixin giant {
  @media screen and (min-width: #{$giant}) {
    @content;
  }
}
@mixin enormous {
  @media screen and (min-width: #{$enormous}) {
    @content;
  }
}
@mixin mobileOnly {
  @media screen and (max-width: #{$medium - 1}) {
    @content;
  }
}
@mixin canHover {
	@media screen and (hover: hover) {
		@content;
	}
}

// fonts
@mixin CircularBook() {
  font-family: "CircularBook", sans-serif;
  font-weight: lighter;
}
@mixin CircularMedium() {
  font-family: "CircularMedium", sans-serif;
  font-weight: normal;
}
@mixin CircularBold() {
  font-family: "CircularBold", sans-serif;
  font-weight: bold;
}
@mixin CircularBlack() {
  font-family: "CircularBlack", sans-serif;
  font-weight: bolder;
}

@mixin fluidType($min: 16px, $max: 24px) {
  font-size: calc(#{$min} + #{(($max / ($max * 0 + 1)) - ($min / ($min * 0 + 1)))} * ( (100vw - #{$small}) / #{(($giant / ($giant * 0 + 1)) - ($small / ($small * 0 + 1)))}));
  @media screen and (max-width: $small) {
    font-size: $min;
  }
  @media screen and (min-width: $giant) {
    font-size: $max;
  }
}

@mixin lineClamp($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Sometimes we want an H1 to look like an H3 and vice-versa
// @mixin tinyType() {
//   @include fluidType(10px, 12px);
// }
// @mixin smallType() {
//   @include fluidType(16px, 24px);
// }
// @mixin mediumType() {
//   @include fluidType(20px, 32px);
// }
// @mixin largeType() {
//   @include fluidType(30px, 40px);
// }
// @mixin giantType() {
//   @include fluidType(40px, 50px);
// }
// @mixin enormousType() {
//   @include fluidType(48px, 80px);
// }

// Wrap stuff sometimes
@mixin container($maxWidth: $containerWidth, $fullBleed: false) {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;

  @if $fullBleed {
    width: 100%;
    @include medium() {
      width: 92%;
      max-width: $maxWidth;
    }
  } @else {
    width: 92%;
    max-width: $small;
    @include medium() {
      width: 80%;
      max-width: $maxWidth;
    }
  }
}
@mixin containerWide() {
  @include container($maxWidth: $containerWidthWide, $fullBleed: true);
}
@mixin containerNarrow() {
  max-width: $containerWidthNarrow;
  margin-left: auto;
  @include medium() {
    width: 67vw;
  }
}


// Clickable!
@mixin clickable() {
  position: relative;
  user-select: none;
  cursor: pointer;
  &:active {
    top: 2px;
  }
}


// fade out super-long text
@mixin textFade($color: white) {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 72px;
    background: linear-gradient(to right, rgba($color, 0) 0%, rgba($color, 1) 75%);
  }
}

@import '/src/styles/mixins';
@import '/src/styles/variables';

.loadMore {
  width: 100%;
  margin-bottom: 24px;
  padding: 20px;
  border: none;
  @include CircularBold();
  @include fluidType();
  text-transform: uppercase;
  background: black;
  color: white;
  @include canHover() {
    @include clickable();
    &:hover {
      text-decoration: underline;
    }
  }
}

@import '/src/styles/mixins';
@import '/src/styles/variables';

.wrap {
  background: white;
  color: black;
  padding: 16px 16px 8px;
  line-height: 1;
}

.item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 6px 0;
  width: 100%;
  border: none;
  background: none;
  color: $dark;
  font-size: 12px;
  text-align: left;
  @include canHover() {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  :global .material-icons {
    margin-right: 5px;
    font-size: 14px;
  }
}

.itemActive {
  font-weight: bold;
  font-size: 13px;
  @include canHover() {
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }
}
@import '/src/styles/mixins';
@import '/src/styles/variables';
@import '/src/styles/typography';
@import '/src/styles/forms';

// Wrap stuff sometimes
.container {
  @include container();
}
.container-wide {
  @include containerWide();
}
.container-narrow {
  @include containerNarrow();
}

// .video-wrap {
//   position: relative;
//   padding-bottom: 56.25%; // 16:9
//   height: 0;
//   overflow: hidden;
//   background-color: black;
//   iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//   }
// }

// @include mobileOnly {
//   .hidden-mobile {
//     display: none !important;
//   }
// }
// @include medium() {
//   .hidden-desktop {
//     display: none !important;
//   }
// }

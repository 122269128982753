// variables

// Primary colors
$dark: #0e0e0e;
$greyDark: #373737;
$grey: #535353;
$pink: #df1995;
$purple: #937ac8;
$red: #d26068;
$blue: #51beec;
$orange: #FFA214;
$green: #59A990;

// Spacers
// $spacerTiny: 15px;
// $spacerSmall: 20px;
// $spacer: 30px;
// $spacerLarge: 50px;
// $spacerGiant: 80px;

// Breakpoints
$tiny: 360px;
$small: 576px;
$medium: 768px;
$large: 992px;
$giant: 1200px;
$enormous: 1920px;

// Magic numbers
// $navHeight: 60px;
// $navHeightDesktop: 106px;

$containerWidth: 930px;
$containerWidthWide: 1170px;
$containerWidthNarrow: 630px;

@import '/src/styles/mixins';
@import '/src/styles/variables';

.wrap {
  margin-bottom: 24px;
  padding: 16px 16px 8px;
  background: white;
  color: black;
  font-size: 12px;
  line-height: 1;
}

.item {
  display: block;
  padding: 6px 0;
  strong {
    word-break: break-all;
    // @include lineClamp(1);
    // @include textFade();
  }
}

.loadMore {
  width: 100%;
  margin: 0;
  padding: 5px 0;
  border: none;
  background: none;
  color: black;
  @include canHover() {
    @include clickable();
    &:hover {
      text-decoration: underline;
    }
  }
}
@import '/src/styles/mixins';
@import '/src/styles/variables';

.wrap {
  @include container();
  @include medium() {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.nav {
  margin: 24px 0;
  color: $dark;
  em {
    display: block;
    margin-bottom: 5px;
    color: white;
  }

  @include medium() {
    flex: 100% 1;
  }
}

.primary {
  margin: 0;
  @include medium() {
    width: calc(70% - 24px);
  }
}
.secondary {
  @include medium() {
    width: 30%;
  }
}

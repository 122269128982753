@import '/src/styles/mixins';
@import '/src/styles/variables';

.wrap {
  background: black;
}

.container {
  @include container($maxWidth: $containerWidthWide);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  height: 54px;
  z-index: 2;
  transition: height 400ms ease, font-size 400ms ease;

  a {
    color: white;
  }

  .select {
    display: none;
    @include medium() {
      display: flex;
      align-items: center;
    }
  }
  .logout {
    display: none;
    @include medium() {
      display: block;
    }
  }
}
:global .active :local .container {
  height: 40px;
  font-size: 15px;
}

.select {
  font-size: 14px;
}

.selectLabel {
  @include mobileOnly() {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 10px;

    :global .material-icons {
      margin-right: 6px;
    }
  }
}

.selectWrap {
  @include medium() {
    margin-left: 10px;
    width: 18vw;
    max-width: 180px;
  }
}

.branding {
  display: flex;
  align-items: center;
  position: relative;
  @include canHover() {
    &:hover {
      text-decoration: none;
    }
  }

  img {
    height: 38px;
    transition: all 400ms ease;
//    border-radius: 100%;
  }
}
:global .active :local .branding img {
  height: 28px;
}

.name {
  margin-left: 10px;
  font-size: 20px;
//  text-transform: uppercase;
  transition: all 400ms ease;

  strong {
    margin-right: 8px;
    @include CircularBlack();
  }
  span {
    display: none;
    @include large() {
      display: inline;
    }
  }

  @include large() {
    margin-left: 15px;
    font-size: 24px;  
  }
}
:global .active :local .name {
  font-size: 16px;
}

.login {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  transition: all 400ms ease;
  span {
    margin-left: 5px;
  }
}
:global .active :local .login {
  font-size: 10px;
}

.menu {
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0 4%;
  height: 0;
  overflow: hidden;
  background: $grey;
  transition: all 400ms ease;
  @include medium() {
    display: none;
  }
}
.menuOpen {
  height: calc(100vh - 40px);
}

.hamburger {
  width: 44px;
  height: 44px;
  padding: 10px;
  margin-right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include medium() {
    display: none;
  }
}

.logout {
  position: relative;
  top: 58vh;
  @include tiny() {
    top: 65vh;
  }
  @include medium() {
    top: 0;
    padding: 0;
  }

  padding: 10px 20px;
  border: none;
  font-family: "CircularBold", sans-serif;
  text-transform: uppercase;
  background: #000;
  color: #fff;
  font-size: 14px;
  margin: 0 auto;
  display: block;

  & > span {
    display: flex;
    align-items: center;
  }

  span span {
    margin-right: 8px;
  }
}
@import '/src/styles/mixins';
@import '/src/styles/variables';

.wrap {
  background: black;
  padding: 15px 0;

  ul {
    @include container();
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    text-align: right;
  }
  li {
    display: inline-block;
    margin-left: 20px;
    font-size: 13px;
    &:first-child {
      margin-left: 0;
    }
  }
  a {
    color: #cccccc;
  }
}

// typography
@import '/src/styles/mixins';
@import '/src/styles/variables';

@font-face {
  font-family: 'CircularBook';
  src: url('../fonts/CircularStd-Book.woff') format('woff');
}
@font-face {
  font-family: 'CircularMedium';
  src: url('../fonts/CircularStd-Medium.woff') format('woff');
}
@font-face {
  font-family: 'CircularBold';
  src: url('../fonts/CircularStd-Bold.woff') format('woff');
}
@font-face {
  font-family: 'CircularBlack';
  src: url('../fonts/CircularStd-Black.woff') format('woff');
}

body {
  @include CircularBook();
  font-size: 18px;
  // @include fluidType();
  color: white;
  background: $greyDark;
  line-height: 1.5;
  font-kerning: normal;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include CircularBold();
  letter-spacing: 0.015em;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 0 0.25em 0;
  // color: $dark;
}
// h1 {
//   @include giantType();
// }
// h2 {
//   @include largeType();
//   margin-bottom: $spacer;
// }
h3 {
  @include fluidType(18px, 24px);
}

a,
.link {
  text-decoration: none;
  cursor: pointer;
  color: black;
  // @include industrieLight();
  @include canHover() {
    // transition: color 400ms ease;
    &:hover {
      // @include industrieBook();
      // color: $grey;
      text-decoration: underline;
    }
  }
}

p {
  margin-top: 0;
  &:last-child {
    margin-bottom: 0;
  }
}

strong {
  @include CircularBold();
}

// address {
//   font-style: normal;
// }

// input,
// select,
// textarea {
//   @include gothamBook();
//   @include fontSize(14);
// }

// &::placeholder {
//   text-overflow: ellipsis;
// }

// ::-webkit-input-placeholder {
//   color: $light;
//   text-overflow: ellipsis;
// }
// :-moz-placeholder {
//   color: $light;
//   text-overflow: ellipsis;
// }
// ::-moz-placeholder {
//   color: $light;
//   text-overflow: ellipsis;
// }
// :-ms-input-placeholder {
//   color: $light;
//   text-overflow: ellipsis;
// }

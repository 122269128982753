@import '/src/styles/mixins';
@import '/src/styles/variables';

.wrap {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 1;
  user-select: none;
  @include CircularBold();

  span {
    position: relative;
    top: 8px;
    font-size: 60px;
    transform: rotate(270deg);
    transition: top 400ms ease;
  }
}

.inner {
  display: inline-block;
  @include canHover() {
    cursor: pointer;
    &:hover {
      span {
        top: 0;
      }
    }
  }
}
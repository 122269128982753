@import '/src/styles/mixins';
@import '/src/styles/variables';

.miniSpinner {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 2px;
    border: 2px solid transparent;
    // border: 2px solid #000;
    border-radius: 50%;
    animation: mini-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    // border-color: #000 transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes mini-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@import '/src/styles/mixins';
@import '/src/styles/variables';

$offset: 180;
$duration: 2s;
$purple: #937ac8;
$green: #59A990;
$blue: #51beec;
$red: #d26068;
$orange: #FFA214;

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
}

.spinner {
  animation: rotator $duration linear infinite;
}
.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration linear infinite;
}
.path1 {
	stroke: $purple;
}
.path2 {
	stroke: $green;
	animation: dash_ccw $duration linear infinite;
}
.path3 {
	stroke: $blue;
}
.path4 {
	stroke: $red;
	animation: dash_ccw $duration linear infinite;
}
.path5 {
	stroke: $orange;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/2;
    transform: rotate(80deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
 }
 @keyframes dash_ccw {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: -$offset/6;
    transform: rotate(80deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
 }

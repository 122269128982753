// forms
@import '/src/styles/mixins';
@import '/src/styles/variables';

form {
  margin: 0 0 48px 0;
  max-width: 360px;
}

input {
  margin-bottom: 16px;
  padding: 0 0 5px 0;
  border: none;
  border-bottom: 3px solid white;
  font-size: 24px;
  color: white;
  background: none;
  max-width: 100%;
}

button {
  font-size: 18px;
  background: none;
  border: none;
  border-radius: 0;
  color: white;
  padding: 0;
  margin: 8px 0 0;
  transition: all 400ms ease;
  text-decoration: underline;
  @include canHover() {
    &:hover {
      color: $grey;
      cursor: pointer;
    }
  }

  // max-width: 300px;
}
button[type="submit"] {
  display: flex;
  align-items: center;
  justify-content: center;
  // display: block;
  // margin: 0;
  padding: 10px 50px;
  min-width: 180px;
  background: white;
  color: black;
  // font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  text-decoration: none;
  @include CircularBlack();
}
